export default {
  Engineering: [
    {
      id: "engineering-job-1",
      category: "engineering",
      title: "Junior Front End <span>Developer</span>",
      subject: "Junior Front End Developer",
      location: "Tirana / Albania",
      time: "Full Time",
      html: `
          <div style="margin-top: 4.4rem;">
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
          We’re looking for skilled Junior Front End Developers to join our team of tech wizards. If what you see below matches your profile and you can envision yourself working for us, go ahead and apply!
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            What will your responsibilities be?
          </h3>
      
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ You’ll be in charge of developing, maintaining, testing and styling UI/UX components
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Do research on the latest alternative UI libraries & UX solutions, and bring those learnings into your work
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Discuss and work with other team members on issues that arise and the best ways to solve them
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Assist the Dev Team in reaching set goals as well as meet individual deadlines
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Routine application maintenance
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            The ideal candidate for this position should:
          </h3>
      
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Have good knowledge of HTML5 & CSS3
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Have basic knowledge of JavaScript (ES6, jQuery, AJAX)
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ CSS frameworks e.g. Bootstrap
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Knowledgeable of the web’s fundamentals
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Understanding of the DOM & Box Model          
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            Additional skills we appreciate:
          </h3>

          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ VueJS, React JS or Angular knowledge
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ MVC knowledge
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Sass/ Less
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Unit testing
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Responsive design and media queries          
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            Traits we value in a candidate:
          </h3>

          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Be able to work in a team-setting or individually
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Loves to come up with unorthodox solutions
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Willing to improve their skillset
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Take responsibility for their work in meeting the assigned deadlines
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Able to properly communicate their thoughts & ideas              
          </p>
        </div>
            `,
    },
    {
      id: "engineering-job-2",
      category: "engineering",
      title: "Senior Front End <span>Developer</span>",
      subject: "Senior Front End Developer",
      location: "Tirana / Albania",
      time: "Full Time",
      html: `
          <div style="margin-top: 4.4rem;">
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            As we take PaperClicks on the next step of its journey, it’s time we bring in the heavy guns, and that’s why we are looking to add a Senior Front End Developer to our roster. If what you see below matches your profile and you can envision yourself working for us, go ahead and apply!
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            What will your responsibilities be?
          </h3>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
          ･ Work with the Design team to gather wireframes, visual mockups, graphics, audio, video & other elements, and translate them into cross-browser responsive UI elements
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Work with the Quality Assurance (QA) team to get the product tested and address any issue that may arise
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Deliver functional, performant, tested, clean and cross-browser code
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Supporting, troubleshooting, innovating and improving existing codes
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            The ideal candidate for this position should:
          </h3>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
          ･ Have at least 3 years of working experience in building complex and modern user interfaces using Vue JS (preferred), Angular or React JS
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ High understanding of Web Markup, including HTML5, CSS3 (SASS/LESS)
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ High understanding of APIs and Http protocol fundamentals
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Familiarity with Webpack
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Be able to quickly integrate into existing complex applications
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Able to research, analyze and recommend new technologies (we value your input)      
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Great skills in creating and maintaining technical documentation
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            Traits we value in a candidate:
          </h3>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Collaborate will fellow developers, designers and other team members         
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Always aiming to self-improve themself
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Can work in a team-setting or fully independent
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Meet deadlines before they meet them
          </p>
        </div>
            `,
    },
    {
      id: "engineering-job-3",
      category: "engineering",
      title: "Junior Back End <span>Developer</span>",
      subject: "Junior Back End Developer",
      location: "Tirana / Albania",
      time: "Full Time",
      html: `
        <div style="margin-top: 4.4rem;">
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            We’re looking to add a talented Junior Back End Developer to our team. If what you see below matches your profile and you can envision yourself working for us, go ahead and apply!
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            What will your responsibilities be?
          </h3>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Get familiar with the work process, the team, the products, and understand the business logic of the company 
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Select your weapon of choice and master it: NodeJS (our preferred choice), Golang, Typescript or bring your own
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Start putting everything together with the hands-on tasks that you’ll be assigned  
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Ask questions, do your own research, improve 
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            The ideal candidate for this position should:
          </h3>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Have a good understanding of OO and MVC programming paradigms
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Have a solid understanding of Web fundamentals and HTTP protocol
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Have a good understanding of what an API is and preferably some experience of working with APIs
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Have a solid understanding of SQL or NoSQL database principles
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Have basic Git knowledge
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            Additional skills we appreciate:
          </h3>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Any experience with Kubernetes, Docker, CI/CD tooling         
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Any experience with NodeJS
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Any experience with Testing (unit testing or integration testing)        
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            Traits we value in a candidate:
          </h3>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Be able to work in a team-setting or individually           
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Loves to come up with unorthodox solutions 
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Willing to improve their skillset     
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Take responsibility for their work
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Able to properly communicate their thoughts & ideas
          </p>
        </div>
            `,
    },
    {
      id: "engineering-job-4",
      category: "engineering",
      title: "Senior Back End <span>Developer</span>",
      subject: "Senior Back End Developer",
      location: "Tirana / Albania",
      time: "Full Time",
      html: `
          <div style="margin-top: 4.4rem;">
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            As we take PaperClicks on the next step of its journey, it’s time we bring in the heavyweights, and that’s why we are looking to add a Senior Back End Developer to our roster. If what you see below matches your profile and you can envision yourself working for us, go ahead and apply!
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            What will your responsibilities be?
          </h3>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Get familiar with the working process, the team, the products and the business logic of the company
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Get familiar with the current infrastructure components, the microservices, the tech stack used to build them, and feel free to bring your experience in
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ From software design, to software development, advanced microservice infrastructure, big data databases, serverless paradigm, etc. Choose your favorite playground and master it
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Actively participate in the lifecycle of an existing or new project. From the idea, the design, to the development and the launch
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Communicate technical and design requirements 
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Write high-quality reusable code that ensures top-notch performance
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Collaborate with the Front End and QA teams
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            Tech Skills:
          </h3>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ At least 3 years of backend development experience with complex web applications. Preferably using NodeJS, but we also like Golang or Python
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Very good hands-on experience with  SQL or NoSQL databases, including design patterns and troubleshooting.
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Ability to quickly integrate into complex systems, analyze existing code, suggest and apply improvements where necessary
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Having the good habit and the ability to first design and then implement.
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Great communication skills. Translate ideas into easily digestible technical documentation or wireframes for the team.
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Deep understanding of Web fundamentals, HTTP protocol stack, APIs, etc.
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Familiarity with Unit and Integration testing and with writing code that is easily testable
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Familiarity with microservices
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Goal-oriented. Meet deadlines before they meet them.
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            Additional skills we appreciate:
          </h3>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Any experience with Kubernetes, Docker, CI/CD tooling
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Any DevOps skills
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Any experience with big data or OLAP DBMS
          </p>
        </div>

        <div>
          <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
            Traits we value in a candidate:
          </h3>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Be able to work in a team-setting or individually
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Loves to come up with unorthodox solutions
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Willing to continuously improve their skillset
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Take responsibility for their work. Lead new projects or teams 
          </p>
          <p style="line-height: 2.6rem; font-weight: 300; color: #2a1748;">
            ･ Able to properly communicate their thoughts & ideas 
          </p>
        </div>
            `,
    },
  ],
  Design: [
    {
      id: "design-job-1",
      category: "design",
      title: "Junior Graphic <span>Designer</span>",
      subject: "Junior Graphic Designer",
      location: "Tirana / Albania",
      time: "Full Time",
      html: `
    <div style="margin-top: 4.4rem;">
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        We’re looking for a talented Junior Graphic Designer to join our ever-growing All-Star Design Team. If what you see below matches your profile and you can envision yourself working for us, go ahead and apply!      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748; margin-top: 3.3rem;">
        The ideal candidate should be someone who loves to come up with unique and creative ideas. After a task commences, we welcome the input and expertise of each member, and at this stage, experimentation and exploration are the keywords. At PaperClicks we simply aim to be better than the rest of the competition, so we support those ideas and designs that can make us become so.
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748; margin-top: 3.3rem;">
        If your eyes have been mutated to see pixel mistakes, well you’re the kind of X-Men we are looking for.
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748; margin-top: 3.3rem;">
        And don’t worry if you haven’t fully created your style or shown the best of you yet, if you’re willing to learn and grow your skillset, you’ll find an ideal space in our offices.
      </p>
    </div>

    <div>
      <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
        What you’ll do?
      </h3>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ The chosen candidate will be responsible for creating designs & other creatives based on given specifications, which can vary from a single indicator to fully-detailed projects
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ Collaborate with the Head of Design and other team members
        especially for people less familiar with project management software.
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
      ･ Work on various design-related tasks, such as promotional materials
      </p>
    </div>

    <div>
      <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
        Requirements:
      </h3>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ 2-3 years of experience as a Graphic Designer
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ Skilled in the following programs: Figma, Adobe Photoshop, Adobe Illustrator and Adobe InDesign
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ Be able to work in a team setting or take and complete a task on their own
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ Manage tasks and deadlines
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ Enjoy a challenge and be able to come with out-of-the-box solutions
      </p>
    </div>

    <div>
      <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
        Bonus:
      </h3>

      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ Current or retired gamer
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ Familiar with geek culture
      </p>
    </div>
        `,
    },
  ],
  "Content Writer": [
    {
      id: "writer-job-1",
      category: "writer",
      title: "Junior Content <span>Writer</span>",
      subject: "Junior Content Writer",
      location: "Tirana / Albania",
      time: "Full Time",
      html: `
    <div style="margin-top: 4.4rem;">
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        The application for this position is pretty straightforward, send on the application three pieces of your writing, of which 2/3 should be of a professional context.
      </p>
    </div>

    <div>
      <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
        Details on the application:
      </h3>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ Recently published pieces will be appreciated, but if you feel that they don’t fully show your skill level, you may also attach older pieces
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ Do not include more than 3 pieces, only if you strongly believe an additional one would be necessary to show your writer’s profile, you may add it
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ Additionally, include your CV/ Resume as well. Though your CV/ Resume i.e. your work experiences will be taken into account, we would like to base a large part of our decision on the applicant’s raw talent, skill and ability
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ On that note, it’s beneficial to have at least one year of work experience as a content or copywriter
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ The 3rd (or 4th) piece you include, could be of any nature. We won’t discriminate, send the things you are most proud to have written
      </p>
    </div>

    <div>
      <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
        Other things to know before applying:
      </h3>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
      ･ This position is in English, hence a high level of the language is required
      </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
      ･ If you think your English still needs improving, then you are one step closer to being selected. At PaperClicks one of our core principles is to continuously improve our skillset
      </p>
    </div>

    <div>
      <h3 style="margin-top: 7rem; margin-bottom: 1rem; font-size: 2.2rem; color: #000; font-weight: 600;">
        Bonus elements to have which will impact your selection only by 0.1%:
      </h3>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
        ･ Ability to speak and understand a conceptual and “philosophical” level of English (this might seem like a no-brainer, but on the application, your writing abilities will be judged, not your speaking ones). The Senior Content Writer, with whom you will be collaborating a lot, has a problem with Albanian sometimes and chooses English as a medium of communication.
        </p>
      <p style="line-height: 3rem; font-weight: 300; color: #2a1748;">
      ･ Nothing else really, just good luck and don’t be shy to apply.
      </p>
    </div>
        `,
    },
  ],
};
