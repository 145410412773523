<template>
  <section class="ceo-section">
    <div class="container">
      <div class="ceo-section__wrapper">
        <img
          src="../../assets/about/about-ceo.png"
          alt=""
          class="ceo-section__image"
        />
        <div class="ceo-section__content">
          <h4 class="ceo-section__title">A word by our CEO</h4>
          <p class="ceo-section__name">Ervin Hoxha</p>
          <p class="ceo-section__text body-text--white">
            Welcome to PaperClicks! The name that brings under its umbrella the
            leading tools in Affiliate Marketing and a team of remarkably gifted
            individuals. What we do here, supports the daily work of thousands
            of marketers and product owners. Thanks for the quick visit and if
            you can envision yourself as part of our team, see how you can join
            the journey.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CEO",
};
</script>

<style lang="scss" scoped>
.ceo-section {
  margin-top: 20rem;
  padding-bottom: 7rem;

  @include media-breakpoint-down(md) {
    margin-top: 13rem;
    padding-bottom: 2rem;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 13rem;
    padding-bottom: 0rem;
  }

  &__wrapper {
    background: #441d82;
    position: relative;
    padding: 5.5rem 13rem;
    border-radius: $border-radius-lg;
    @include media-breakpoint-down(lg) {
      padding: 3rem 8rem;
    }

    @include media-breakpoint-down(md) {
      padding: 5rem;
      width: 100%;
      height: 73rem;
      margin: 0 auto;
    }

    @include media-breakpoint-down(sm) {
      padding: 3rem;
      overflow: hidden;
    }
  }
  &__image {
    position: absolute;
    bottom: 0;
    left: 4rem;
    height: 42rem;

    @include media-breakpoint-down(lg) {
      height: 37rem;
    }

    @include media-breakpoint-down(md) {
      left: 50%;
      transform: translateX(-50%);
      height: 43rem;
    }
    @include media-breakpoint-down(sm) {
      left: 50%;
      transform: translateX(-50%);
      height: 35rem;
    }
    @include media-breakpoint-down(xs) {
      height: 33rem;
    }
  }

  &__content {
    width: 50%;
    margin-left: auto;
    position: relative;

    @include media-breakpoint-down(md) {
      width: 100%;
      position: relative;
    }

    &::before {
      content: "“";
      position: absolute;
      top: -6rem;
      right: -6rem;
      font-size: 7.5rem;
      color: $color-white;
      font-weight: 800;
      transform-origin: center;
      transform: rotate(180deg);

      @include media-breakpoint-down(lg) {
        top: -5rem;
        right: -4rem;
        font-size: 7rem;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__title {
    color: $color-white;
    font-size: 2.8rem;
    font-weight: 700;
    @include media-breakpoint-down(lg) {
      font-size: 2.5rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 2.8rem;
      font-weight: 600;
    }
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
      font-weight: 600;
    }
  }
  &__name {
    color: #26c0e2;
    font-weight: 600;
    margin-top: 0.3rem;

    @include media-breakpoint-down(md) {
      margin-top: 2rem;
      font-size: 1.8rem;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 0.7rem;
      font-size: 1.6rem;
    }
  }
  &__text {
    margin-top: 1rem;
    line-height: 2.6rem;
    font-weight: 300;
    font-size: 1.5rem;
    color: $color-white;

    @include media-breakpoint-down(md) {
      margin-top: 2.5rem;
      font-size: 1.6rem;
    }
  }
}
</style>
