<template>
  <section class="positions-section" id="job-positions">
    <div class="container">
      <b-row>
        <div class="positions-section__heading container">
          <h3 class="section-title">
            Open Positions
          </h3>
          <p class="body-text--black">
            We’re constantly looking for talented individuals in all fields. If
            you see an opening that matches your skills, go ahead and apply!
          </p>
        </div>

        <b-col
          v-for="(item, index) in Object.entries(jobs)"
          cols="12"
          :key="index"
        >
          <div class="positions-section__jobs">
            <h5 class="positions-section__jobs__title">{{ item[0] }}</h5>
            <Position
              v-for="{ title, category, id } in item[1]"
              :title="title"
              :category="category"
              :id="id"
              :key="id"
              class="mt-5"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import Position from "@/components/about/Position.vue";
import jobs from "@/content/job-positions.js";

export default {
  name: "PositionsSection",
  components: {
    Position,
  },
  data() {
    return {
      jobs,
    };
  },
};
</script>

<style lang="scss">
.positions-section {
  padding-top: 10rem;
  text-align: center;
  margin-bottom: 16rem;

  @include media-breakpoint-down(md) {
    padding-top: 8rem;
  }

  @include media-breakpoint-down(sm) {
    text-align: start;
    margin-bottom: 13rem;
  }

  & .section-title {
    margin-bottom: 1rem;
  }
  & .body-text--black {
    width: 70%;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  &__jobs {
    width: 80%;
    margin: 0 auto;
    margin-top: 6rem;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    &__title {
      text-align: start;
      font-size: 1.8rem;
      font-weight: 700;
      color: $color-black;

      @include media-breakpoint-down(sm) {
        font-size: 2.2rem;
        margin-bottom: -1rem;
      }
    }
  }
}
</style>
