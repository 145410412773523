<template>
  <section class="job-description">
    <h3 class="section-title" v-html="content.title"></h3>
    <div class="job-description__info">
      <div class="job-description__info__item job-description__info__time">
        <img src="../../assets/careers/time-icon.svg" alt="Clock Icon" />
        <p>{{ content.time }}</p>
      </div>
      <div class="job-description__info__item job-description__info__location">
        <img src="../../assets/careers/location-icon.svg" alt="Clock Icon" />
        <p>{{ content.location }}</p>
      </div>
    </div>
    <div v-html="content.html"></div>
  </section>
</template>

<script>
export default {
  name: "Jobdescription",
  props: {
    content: { type: Object, default: () => {} },
  },
};
</script>

<style lang="scss">
.job-description {
  &__info {
    margin-top: 1.6rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    @include media-breakpoint-down(md) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    &__item {
      width: max-content;
      padding: 0.8rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-primary;
      border-radius: $border-radius-xs;
      font-weight: 500;
      @include media-breakpoint-down(xs) {
        margin-top: 2rem;
      }
    }

    &__time {
      background-color: #ece8f3;
      color: $color-primary-light;
      margin-right: 2rem;

      & img {
        height: 2.2rem;
        margin-right: 0.4rem;
      }
    }

    &__location {
      background-color: #ece8f3;
      margin-right: 2rem;

      & img {
        height: 2.2rem;
        margin-right: 0.2rem;
      }
    }

    &__share {
      padding: 0 !important;

      & img {
        height: 2.2rem;
        margin-right: 0.4rem;
      }
    }
  }

  &__title {
    margin-top: 7rem;
    margin-bottom: 1rem;
    font-size: 2.2rem;
    font-weight: 600;
    color: $color-black;
  }

  &__list {
    list-style: none !important;
    &__items {
      margin-top: 1.4rem;

      & img {
        height: 2.2rem;
        margin-right: 1.1rem;
      }
    }
  }
}
</style>
