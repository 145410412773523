<template>
  <section class="article-text" v-html="content"></section>
</template>

<script>
export default {
  name: "ArticleInfo",
  props: {
    content: { type: String },
  },
};
</script>

<style lang="scss"></style>
