<template>
  <div class="blog">
    <Article />
  </div>
</template>

<script>
import Article from "../components/blog/Article.vue";
export default {
  name: "ArticleView",
  components: {
    Article,
  },
};
</script>

<style lang="scss" scoped>
// .blog {
//   margin-top: 10rem;

//   @include media-breakpoint-down(md) {
//     margin-top: 6rem;
//   }
// }
</style>
