<template>
  <section class="office-section">
    <div class="office-section__bg">
      <video
        class="video-paperclicks--desktop"
        src="../../assets/about/video-paperclicks.mp4"
        autoplay
        playsinline
        loop
        muted
      ></video>
      <video
        class="video-paperclicks--mobile"
        src="../../assets/about/video-paperclicks-mobile.mp4"
        autoplay
        playsinline
        loop
        muted
      ></video>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-6">
          <div class="office-section__content">
            <h3 class="section-title">
              More than a Company, your Next Unicorn
            </h3>
            <p class="body-text--black">
              At PaperClicks, we’ve always focused on creating a company culture
              and working environment that enables our teammates to show what
              they can do and also polish & advance their skills. Inside our
              doors you’ll find individuals with different backgrounds and
              experiences, making the space an ideal one to learn, grow and
              share the wildest of ideas.
            </p>
            <p class="body-text--black">
              Our offices have been designed to create a tranquil and
              comfortable environment, for those who want to immerse themselves
              in their work, or take a break to properly relax. Modern and
              spacious, for all work actives and plenty of personal space. We
              are always trying to improve, and your input can take us to the
              next level.
            </p>
          </div>
        </div>
        <div class="col-6">
          <div class="office-section__images">
            <img
              src="@/assets/about/about-office-img-1.jpg"
              alt=""
              class="office-section__image office-section__image-1"
            />
            <img
              src="@/assets/about/about-office-img-2.jpg"
              alt=""
              class="office-section__image office-section__image-2"
            />
            <img
              src="@/assets/about/about-office-img-3.jpg"
              alt=""
              class="office-section__image office-section__image-3"
            />
            <img
              src="@/assets/about/about-office-img-4.jpg"
              alt=""
              class="office-section__image office-section__image-4"
            />
            <img
              src="@/assets/about/about-office-img-5.jpg"
              alt=""
              class="office-section__image office-section__image-5"
            />
          </div>
        </div>
        <div class="col-12 office-section__mobile container">
          <div class="office-section__mobile__images">
            <img
              src="@/assets/about/about-office-img-1.jpg"
              alt=""
              class="office-section__mobile__image office-section__mobile__image-1"
            />
            <img
              src="@/assets/about/about-office-img-2.jpg"
              alt=""
              class="office-section__mobile__image office-section__mobile__image-2"
            />
            <img
              src="@/assets/about/about-office-img-3.jpg"
              alt=""
              class="office-section__mobile__image office-section__mobile__image-3"
            />
            <img
              src="@/assets/about/about-office-img-4.jpg"
              alt=""
              class="office-section__mobile__image office-section__mobile__image-4"
            />
            <img
              src="@/assets/about/about-office-img-5.jpg"
              alt=""
              class="office-section__mobile__image office-section__mobile__image-5"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OfficeSection",
};
</script>

<style lang="scss" scoped>
.office-section {
  // overflow-x: hidden;

  @include media-breakpoint-down(md) {
    overflow-x: hidden;
  }
  &__bg {
    width: 100vw;
    margin-bottom: 10rem;
    position: relative;

    @include media-breakpoint-down(lg) {
      margin-bottom: 8rem;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 6rem;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 3rem;
    }
  }

  video {
    // height: min-content;
    width: 100vw;
    margin-top: -0.5rem;
  }
  &__content {
    width: 95%;
    height: 100%;
    padding: 6rem 0;

    @include media-breakpoint-down(lg) {
      margin-top: -4rem;
    }

    & .body-text--black {
      margin-top: 3rem;
    }
    .section-title {
      width: 100%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  &__images {
    position: relative;
    width: 100%;
    height: 100%;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  &__image {
    display: block;
    position: absolute;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-photos;
    pointer-events: all;
    transition: all 0.15s ease-in-out;

    &:hover {
      z-index: 20;
      box-shadow: $box-shadow-photos-hover;
      transform: scale(1.2);
    }
  }

  &__image-1 {
    top: 4%;
    left: -5%;
    height: 33%;
    z-index: 12;
  }
  &__image-2 {
    top: 13%;
    right: -2%;
    height: 34%;
    z-index: 9;
  }
  &__image-3 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 32%;
    z-index: 11;
    &:hover {
      box-shadow: (0px 24px 34px rgba(0, 0, 0, 0.2));
      transform: translate(-50%, -50%) scale(1.25);
      z-index: 20;
    }
  }
  &__image-4 {
    bottom: 13%;
    left: 0%;
    height: 33%;
    z-index: 10;
  }
  &__image-5 {
    bottom: 9%;
    right: -5%;
    height: 29%;
    z-index: 10;
  }
}

.office-section__mobile {
  display: none;

  @include media-breakpoint-down(lg) {
    display: block;
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__image {
    height: 20rem !important;
    display: block;
    width: max-content;
    margin-top: 3rem;
    border-radius: $border-radius-lg;

    @include media-breakpoint-down(md) {
      // height: 16rem !important;
      width: 100%;
      height: auto !important;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: auto !important;
    }

    &:nth-child(2) {
      margin-left: 2rem;
      margin-right: 2rem;
      @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-left: 0;
      }
    }
    &:last-child {
      margin-left: 2rem;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
      }
    }
  }
}

.video-paperclicks--desktop {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.video-paperclicks--mobile {
  display: none;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}
</style>
