var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"article"},[_c('div',{staticClass:"article-header"},[_c('ArticleHeader',{attrs:{"content":_vm.currentArticle}})],1),_c('b-container',[_c('div',{staticClass:"article-body"},[_c('b-row',[_c('b-col',{staticClass:"article-body__content",attrs:{"cols":"12","lg":"8"}},[_c('ArticleInfo',{attrs:{"content":_vm.currentArticle.html}})],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"article-body__other-articles"},[_c('h3',{staticClass:"article-body__other-articles__title"},[_vm._v(" Other Articles ")]),_c('div',{staticClass:"articles"},_vm._l((_vm.otherArticles),function(ref){
var id = ref[0];
var ref_1 = ref[1];
var image = ref_1.image;
var title = ref_1.title;
var author = ref_1.author;
var date = ref_1.date;
return _c('ArticleCard',{key:id,staticClass:"article-body__other-articles__article",attrs:{"id":id,"image":image,"title":title,"author":author,"date":date}})}),1),_c('div',{staticClass:"articles-big"},_vm._l((_vm.otherArticles),function(ref){
var id = ref[0];
var ref_1 = ref[1];
var image = ref_1.image;
var title = ref_1.title;
var date = ref_1.date;
return _c('BlogCard',{key:id,staticClass:"article-body__other-articles__article",attrs:{"image":image,"title":title,"date":date,"id":id}})}),1)]),_c('div',{staticClass:"article-body__jobs"},[_c('p',{staticClass:"article-body__jobs__title"},[_vm._v(" There are "),_c('span',{staticClass:"article-body__jobs__title--color"},[_vm._v("6")]),_vm._v(" available job positions ")]),_c('b-btn',{staticClass:"primary-button primary-button--pink",attrs:{"to":'/about#job-positions'}},[_vm._v("Join the Team")])],1)])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }