<template>
  <section class="job-application">
    <b-container>
      <b-row class="row">
        <b-col xl="8">
          <JobDescription :content="currentJob" />
        </b-col>
        <b-col xl="4">
          <div class="email-card__jobs">
            <div class="email-card__benefits">
              <h3 class="email-card__benefits__title">
                Benefits:
              </h3>
              <ul class="email-card__benefits__list">
                <li class="email-card__benefits__list__items">
                  <img src="../../assets/careers/check-icon.svg" alt="" />
                  <p>A competitive salary</p>
                </li>
                <li class="email-card__benefits__list__items">
                  <img src="../../assets/careers/check-icon.svg" alt="" />
                  <p>Goal-oriented working mentality</p>
                </li>
                <li class="email-card__benefits__list__items">
                  <img src="../../assets/careers/check-icon.svg" alt="" />
                  <p>Flexible office hours</p>
                </li>
                <li class="email-card__benefits__list__items">
                  <img src="../../assets/careers/check-icon.svg" alt="" />
                  <p>State of the art workspace</p>
                </li>
                <li class="email-card__benefits__list__items">
                  <img src="../../assets/careers/check-icon.svg" alt="" />
                  <p>Perfect environment to hone your skills</p>
                </li>
                <li class="email-card__benefits__list__items">
                  <img src="../../assets/careers/check-icon.svg" alt="" />
                  <p>Various team building activities</p>
                </li>
              </ul>
            </div>
            <b-btn
              :href="`mailto:hr@paperclicks.net?subject=${currentJob.subject}`"
              target="_blank"
              class="primary-button primary-button--pink"
              >Apply For This Position</b-btn
            >
            <p class="email-card__jobs__title ">
              or email us at: <span>hr@paperclicks.net</span>
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import JobDescription from "./JobDescription.vue";
import jobs from "@/content/job-positions.js";

export default {
  name: "JobApplication",
  components: { JobDescription },
  data() {
    return {
      currentJob: {},
      allJobPositions: [],
    };
  },
  methods: {
    handleLoad() {
      this.handleAllJobPositions();

      let { id } = this.$route.query;

      if (id) {
        id = String(id);
        const currentIds = this.allJobPositions.map((item) => item.id);
        if (currentIds.includes(id)) {
          this.currentJob = this.allJobPositions.find((item) => item.id === id);
        } else {
          [this.currentJob] = this.allJobPositions;
          this.$router.replace({ query: { id: this.currentJob.id } });
        }
      } else {
        [this.currentJob] = this.allJobPositions;
        this.$router.replace({ query: { id: this.currentJob.id } });
      }
    },
    handleAllJobPositions() {
      const categories = Object.keys(jobs);

      categories.forEach((category) => {
        this.allJobPositions.push(...[...jobs[category]]);
      });
    },
  },
  watch: {
    "$route.query.id": {
      handler() {
        this.handleLoad();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.job-application {
  margin-top: 10rem;
  margin-bottom: 15rem;
  @include media-breakpoint-down(md) {
    margin-top: 5rem;
    margin-bottom: 10rem;
  }
}

.email-card__jobs {
  position: sticky;
  top: 10rem;
  background: #441d82;
  border-radius: 1rem;
  padding: 3rem;
  margin-top: 5rem;
  @include media-breakpoint-down(sm) {
    margin-top: 10rem;
  }
  &__title {
    margin-top: 2.5rem;
    font-size: 2rem;
    font-weight: 300;
    color: $color-white;
    span {
      font-weight: 500;
      color: $color-blue-light;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }
  & .primary-button {
    width: 100%;
    margin-top: 3rem;
    padding-left: 0;
    padding-right: 0;
  }
}

.email-card__benefits {
  margin: 0;

  &__title {
    font-size: 2.3rem;
    font-weight: 500;
    color: $color-white;
  }
  &__list {
    list-style: none;
    margin-top: 3rem;

    &__items {
      display: flex;
      align-items: flex-start;
      margin-top: 1.5rem;
      font-size: 1.6rem;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.8);
      img {
        margin-right: 1rem;
        margin-top: 0.2rem;
        height: 2rem;
        padding-top: 0.1rem;
      }
    }
  }
}
</style>
