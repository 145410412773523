<template>
  <div :class="`position-card ${category}`">
    <h5 class="position-card__title" v-html="title"></h5>
    <b-btn :to="{ name: 'Careers', query: { id } }" class="primary-button"
      >Apply Now</b-btn
    >
  </div>
</template>

<script>
export default {
  name: "Position",
  props: {
    title: { type: String },
    category: { type: String },
    id: { type: [String, Number] },
  },
};
</script>

<style lang="scss">
.position-card {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-white;
  border-radius: $border-radius-sm;
  box-shadow: $box-shadow-purple;
  margin-top: 5rem !important;

  @include media-breakpoint-down(md) {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    padding-top: 5rem;
  }

  &.engineering {
    &::before {
      background-color: #7cd5e0;
    }
  }

  &.design {
    &::before {
      background-color: #e5be7a;
    }
  }

  &.writer {
    &::before {
      background-color: #ed7796;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2%;

    @include media-breakpoint-down(md) {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 1.5rem;
      width: 100%;
    }
  }

  &__title {
    margin-left: 4rem;
    font-size: 1.8rem;
    font-weight: 700;
    color: $color-black;

    @include media-breakpoint-down(md) {
      margin: 0;
      text-align: center;
    }
    span {
      @include media-breakpoint-down(md) {
        display: block;
        margin-top: 0.8rem;
      }
    }
  }

  .primary-button {
    @include media-breakpoint-down(md) {
      margin-top: 3rem;
      width: 100%;
    }
  }
}
</style>
