<template>
  <router-link :to="{ name: 'Blog', query: { id } }">
    <div class="other-article__card">
      <img class="other-article__card__img" :src="image" alt="Article Image" />
      <div class="other-article__content">
        <p class="other-article__content__title">
          {{ title }}
        </p>
        <p class="other-article__content__text">
          {{ author }} &nbsp; {{ date }}
        </p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ArticleCard",
  props: {
    id: { type: [Number, String] },
    image: { type: String },
    title: { type: String },
    author: { type: String },
    date: { type: String },
  },
  computed: {
    articleImage() {
      return require(`../../assets/blog/${this.image}.png`);
    },
  },
};
</script>

<style lang="scss">
.other-article__card {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__img {
    width: 13rem;
    border-radius: $border-radius-xs;
  }
}

.other-article__content {
  margin-left: 1rem;
  &__title {
    color: $color-primary;
    font-weight: 600;
  }
  &__text {
    color: $color-gray;
    margin-top: 0.7rem;
    font-size: 1.4rem;
  }
}
</style>
