<template>
  <section class="article">
    <div class="article-header">
      <ArticleHeader :content="currentArticle" />
    </div>
    <b-container>
      <div class="article-body">
        <b-row>
          <b-col cols="12" lg="8" class="article-body__content">
            <ArticleInfo :content="currentArticle.html" />
          </b-col>
          <b-col cols="12" lg="4">
            <div class="article-body__other-articles">
              <h3 class="article-body__other-articles__title">
                Other Articles
              </h3>
              <div class="articles">
                <ArticleCard
                  class="article-body__other-articles__article"
                  v-for="[id, { image, title, author, date }] in otherArticles"
                  :key="id"
                  :id="id"
                  :image="image"
                  :title="title"
                  :author="author"
                  :date="date"
                />
              </div>
              <div class="articles-big">
                <BlogCard
                  v-for="[id, { image, title, date }] in otherArticles"
                  :key="id"
                  :image="image"
                  :title="title"
                  :date="date"
                  :id="id"
                  class="article-body__other-articles__article"
                />
              </div>
            </div>
            <div class="article-body__jobs">
              <p class="article-body__jobs__title">
                There are
                <span class="article-body__jobs__title--color">6</span>
                available job positions
              </p>
              <b-btn
                :to="'/about#job-positions'"
                class="primary-button primary-button--pink"
                >Join the Team</b-btn
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </section>
</template>

<script>
import ArticleHeader from "../blog/ArticleHeader.vue";
import ArticleInfo from "../blog/ArticleInfo.vue";
import ArticleCard from "../blog/ArticleCard.vue";
import BlogCard from "../BlogCard.vue";

import articles from "@/content/articles";

export default {
  components: {
    ArticleHeader,
    ArticleInfo,
    ArticleCard,
    BlogCard,
  },
  data() {
    return {
      articles,
      currentArticle: {},
      blogCards: [
        {
          image: "blog-1",
          title: "Best marketing tools to use for your new Start - Up",
          link: "/about",
          date: "19.06.2021",
        },
        {
          image: "blog-2",
          title: "Best marketing tools to use for your new Start - Up",
          link: "/about",
          date: "19.06.2021",
        },
        {
          image: "blog-3",
          title: "Best marketing tools to use for your new Start - Up",
          link: "/about",
          date: "19.06.2021",
        },
      ],
    };
  },
  methods: {
    handleLoad() {
      let { id } = this.$route.query;

      if (id) {
        id = String(id);
        const currentIds = Object.keys(this.articles);
        if (currentIds.includes(id)) {
          this.currentArticle = this.articles[id];
        } else {
          this.currentArticle = this.articles[1];
          this.$router.replace({ query: { id: 1 } });
        }
      } else {
        this.currentArticle = this.articles[1];
        this.$router.replace({ query: { id: 1 } });
      }
    },
  },
  computed: {
    otherArticles() {
      return Object.entries(this.articles).filter(
        ([key, value]) => key != this.currentArticle.id
      );
    },
  },
  watch: {
    "$route.query.id": {
      handler() {
        this.handleLoad();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.article {
  margin-bottom: 9rem;

  .article-body {
    margin-top: 6rem;
    @include media-breakpoint-down(sm) {
      margin-top: 5rem;
    }

    &__content {
      @include media-breakpoint-up(sm) {
        padding-right: 8rem;
      }
    }

    &__other-articles {
      @include media-breakpoint-down(sm) {
        margin-top: 7rem;
      }

      &__title {
        font-weight: 700;
        font-size: 2.2rem;
        color: $color-primary;
        margin-bottom: 1.6rem;
        @include media-breakpoint-down(sm) {
          margin-bottom: 3rem;
        }
      }

      &__article {
        display: block;
        margin-bottom: 4.5rem;
        outline: none;
      }
    }

    &__jobs {
      background: $color-primary;
      border-radius: 1rem;
      padding: 3rem;
      margin-top: 5rem;
      @include media-breakpoint-down(sm) {
        margin-top: 10rem;
      }
      &__title {
        font-size: 2.2rem;
        font-weight: 500;
        color: $color-white;
        &--color {
          color: $color-blue-light;
        }
      }

      & .primary-button {
        width: 100%;
        margin-top: 2.5rem;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .articles {
    display: block;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .articles-big {
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
}
</style>
