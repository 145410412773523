<template>
  <section class="careers">
    <div class="careers__bg"></div>
    <JobApplication />
  </section>
</template>

<script>
import JobApplication from "@/components/Careers/JobApplication.vue";
export default {
  name: "Careers",
  components: {
    JobApplication,
  },
};
</script>

<style lang="scss" scoped>
.careers {
  margin-top: 6.5rem;

  @include media-breakpoint-down(md) {
    margin-top: 5.8rem;
  }

  &__bg {
    height: 27rem;
    background: url("../assets/careers/careers-bg.svg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    @include media-breakpoint-down(md) {
      height: 20rem;
    }
    @include media-breakpoint-down(sm) {
      height: 19rem;
    }
    @include media-breakpoint-down(xs) {
      height: 22rem;
      background: url("../assets/careers/careers-bg-mobile.svg");
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
</style>
