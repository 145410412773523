<template>
  <div class="article-header">
    <div class="container">
      <h3 class="section-title">
        {{ content.title }}
      </h3>
      <img class="article-header__img" :src="content.image" alt="" />
    </div>
    <img class="article-header__img-mobile" :src="content.image" alt="" />
    <div class="container">
      <div class="article-header__info">
        <p class="body-text--gray">
          by {{ content.author }} &nbsp; {{ content.date }}
        </p>
        <p class="body-text--gray">{{ content.category }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArticleHeader",
  props: {
    content: { type: Object, default: () => {} },
  },
};
</script>

<style lang="scss">
.article-header {
  margin-top: 15rem;

  @include media-breakpoint-down(md) {
    margin-top: 12rem;
  }

  & .section-title {
    width: 70%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  &__info {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      margin-top: 2rem;
    }
  }

  &__img {
    margin-top: 2rem;
    border-radius: 2rem;
    width: 100%;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__img-mobile {
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
      margin-top: 5rem;
      width: 100%;
    }
  }
}
</style>
