<template>
  <div class="about">
    <OfficeSection />
    <CEO />
    <PositionsSection ref="jobPositions" />
    <div class="container button--container sticky-button">
      <b-btn
        @click="clickedButton"
        class="primary-button primary-button--pink primary-button--sticky "
        >We are Hiring!</b-btn
      >
    </div>
  </div>
</template>

<script>
import OfficeSection from "@/components/about/OfficeSection.vue";
import CEO from "@/components/about/CEO.vue";
import PositionsSection from "@/components/about/PositionsSection.vue";
export default {
  components: {
    OfficeSection,
    PositionsSection,
    CEO,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const buttonContainer = document.querySelector(".button--container");
      const lastSectionCoords = document
        .querySelector(".positions-section")
        .getBoundingClientRect().top;
      if (
        window.scrollY > 0 &&
        lastSectionCoords - window.innerHeight + 100 > 0
      ) {
        buttonContainer.classList.remove("sticky-button");
      } else {
        buttonContainer.classList.add("sticky-button");
      }
    },
    clickedButton() {
      this.$refs.jobPositions.$el.scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  margin-top: 6rem !important;

  @include media-breakpoint-down(md) {
    margin-top: 5.5rem;
  }
}
</style>
